import React from 'react';

import { MainCard, Typography } from '@/components';
// import CustomSlider from '../../ui-components/SlickSlider';
// import { technol } from '../../storage/data';
import { Container } from '@/layouts/Container';
import type { TechnologyWorkProcessType } from '@/types/service';
import getImage from '@/utils/getImage';
// styles

export const Technology = ({
  technology,
}: {
  technology: TechnologyWorkProcessType[];
}) => {
  return (
    <section className="bg-primary-900 py-mobileContainer sm:py-container">
      <Container>
        <div data-aos="fade-down">
          <Typography className="text-center text-2xl font-bold tracking-tightest text-white sm:text-5xl">
            Technology Expertise
          </Typography>
        </div>
        <div
          className="mt-6 grid grid-cols-1 gap-4 sm:mt-14 sm:grid-cols-4 sm:gap-8"
          data-aos="fade-in "
        >
          {technology.map((item, idx) => {
            const data = {
              id: item.id,
              thumb: getImage(item.icon.data.attributes.url),
              title: item.title,
              subtitle: item.description,
              featured: false,
            };
            return (
              <MainCard key={idx} data={data} i={idx} withButton={false} />
            );
          })}
        </div>
      </Container>
    </section>
  );
};
