import { useRouter } from 'next/router';
import React from 'react';

import { BlogCard, Button, Heading, ScreenResizer } from '@/components';
// Styles..
import { Container } from '@/layouts/Container';
import type { BlogData } from '@/types/blogResponse';

export const Blogs = ({
  blogs,
  variant,
  title,
}: {
  blogs: BlogData[];
  variant: 'homepage' | 'suggested';
  title: string;
}) => {
  const router = useRouter();
  return (
    <section className="py-mobileContainer sm:py-container" data-aos="fade-in">
      <Container>
        <Heading
          level={2}
          className="p-0 text-center tracking-tightest sm:px-[66.5px]"
        >
          {variant === 'homepage' ? 'Our Blog' : title || 'Suggested Reading'}
        </Heading>

        <div className="mt-6 grid grid-cols-1 gap-6 sm:mt-default sm:grid-cols-3 sm:gap-[30px]">
          {blogs.map((blog) => (
            <BlogCard key={blog.id} data={blog} router={router} />
          ))}
        </div>

        {variant === 'homepage' && (
          <div className="mt-default text-center">
            <ScreenResizer displayOnly="desktop">
              <Button
                variant="outlined"
                label="See All Blog"
                className="border-2 border-primary-100 text-primary-800"
                onClick={() => router.push('/blogs')}
              />
            </ScreenResizer>
            <ScreenResizer displayOnly="mobile">
              <Button
                variant="outlined"
                size="small"
                label="See All Blog"
                className="border-2 border-primary-100 text-primary-800"
                onClick={() => router.push('/blogs')}
              />
            </ScreenResizer>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Blogs;
