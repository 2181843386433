import clsx from 'clsx';
import React from 'react';

import { Heading, ScreenResizer, Typography } from '@/components';

type CounterProps = {
  counters: {
    text: string;
    value: string;
  }[];
  variant?: 'about-section' | 'other';
};

export const Counter = ({ counters, variant = 'other' }: CounterProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-2 gap-5 sm:gap-2',
        variant === 'about-section' && 'sm:grid-cols-2 sm:gap-5',
        variant === 'other' && 'sm:grid-cols-4'
      )}
      data-aos="fade-up"
    >
      {counters.map((counter, idx) => (
        <div key={idx} className="text-center">
          <Heading className="tracking-tightest">
            {`${counter.value}${idx !== 0 ? '+' : ''} `}
          </Heading>

          <ScreenResizer displayOnly="desktop">
            <Typography className="text-2xl" variant="mediumDark">
              {counter.text}
            </Typography>
          </ScreenResizer>
          <ScreenResizer displayOnly="mobile">
            <Typography variant="mediumDark">{counter.text}</Typography>
          </ScreenResizer>
        </div>
      ))}
    </div>
  );
};
