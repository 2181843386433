import React from 'react';

// Styles..
import { Heading } from '@/components';
import type { ClientType } from '@/types/clients';

import { ClientImages } from './ClientImages';

export const Clients = ({ clients }: { clients: ClientType[] }) => {
  return (
    <section className="w-full py-mobileContainer sm:py-container">
      <Heading level={2} className="py-12 text-center">
        Businesses that trust us to solve their problems and help them succeed
      </Heading>

      <ClientImages clients={clients} />
    </section>
  );
};
