import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import Slider from 'react-slick';

import { Heading, ScreenResizer, Typography } from '@/components';
import type { ArrowButtonProps } from '@/components/SlickSlider/types';
import type { TestimonialType } from '@/types/testimonial';
import getImage from '@/utils/getImage';

export const TestimonialSlider = ({
  testimonials,
}: {
  testimonials: TestimonialType[];
}) => {
  const slider = React.useRef(null);

  const ArrowButtonPrevious = ({
    imgSrc,
    imgAlt,
    onClick,
  }: ArrowButtonProps) => {
    return (
      <button
        onClick={onClick}
        className="absolute top-[35%] w-[31.2px] translate-y-[-35%]"
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          // style={{
          //   width: "50px",
          //   height: "50px"
          // }}
        />
      </button>
    );
  };

  const ArrowButtonNext = ({ imgSrc, imgAlt, onClick }: ArrowButtonProps) => {
    return (
      <button
        onClick={onClick}
        className="absolute right-[-2px] top-[35%] w-[31.2px] translate-y-[-35%]"
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          // style={{
          //   width: "50px",
          //   height: "50px"
          // }}
        />
      </button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <ArrowButtonPrevious imgSrc={'/images/prevArrow.svg'} />,
    nextArrow: <ArrowButtonNext imgSrc={'/images/nextArrow.svg'} />,
  };

  return (
    <div>
      <Slider ref={slider} {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id}>
            <div className="px-8 text-center sm:px-[90px]">
              <div className="mx-auto flex w-[200px] items-center justify-center space-y-6">
                <img
                  src={getImage(
                    testimonial.attributes.logo?.data?.attributes.url
                  )}
                  alt={testimonial.attributes.logo_alt}
                />
              </div>

              <Heading
                level={4}
                weight="normal"
                className="pb-4 pt-2 sm:pb-[29px] sm:pt-6"
              >
                {ReactHTMLParser(testimonial.attributes.content)}
              </Heading>

              <div className="mx-auto h-[80px] w-[80px]">
                <img
                  src={getImage(
                    testimonial.attributes.photo?.data?.attributes.url
                  )}
                  alt={testimonial.attributes.alt_photo}
                  className="h-full w-full rounded-full"
                />
              </div>
              <ScreenResizer displayOnly="desktop">
                <Typography
                  size="xl"
                  className="pt-6 font-semibold"
                  variant="mediumDark"
                >
                  {testimonial.attributes.name}
                </Typography>
                <Typography
                  size="large"
                  className="pb-[29px] pt-3"
                  variant="mediumDark"
                >
                  {testimonial.attributes.designation} <br />
                  {testimonial.attributes.company}
                </Typography>
              </ScreenResizer>
              <ScreenResizer displayOnly="mobile">
                <Typography
                  size="large"
                  className="pt-4 font-semibold"
                  variant="mediumDark"
                >
                  {testimonial.attributes.name}
                </Typography>
                <Typography
                  size="large"
                  className="pb-[29px] pt-3"
                  variant="mediumDark"
                >
                  {testimonial.attributes.designation} <br />
                  {testimonial.attributes.company}
                </Typography>
              </ScreenResizer>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
