import { useRouter } from 'next/router';
import React from 'react';
import Typed from 'react-typed';

import { Button, ClutchWidget } from '@/components';
import { TALK_TO_US_URL } from '@/constants/variables';
import { Container } from '@/layouts/Container';

export const HeaderBanner = () => {
  const router = useRouter();

  return (
    <section>
      <Container>
        <div className="flex w-full items-center">
          <div className="sm:w-[60%]">
            <div className="text-center font-titleFont text-[2.5rem] font-bold leading-tight text-primary-900 sm:text-left sm:text-6xl sm:leading-snug">
              Supercharge your business with tailor-made{' '}
              <h1 className="text-secondary-900">
                <Typed
                  strings={['software solutions']}
                  typeSpeed={40}
                  backSpeed={50}
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  loop
                ></Typed>
              </h1>
            </div>
            <div className="flex items-center gap-6 py-mobileContainer sm:flex-col sm:items-start">
              <Button
                label="Talk To Us"
                variant="black"
                onClick={() => router.push(TALK_TO_US_URL)}
              />
              <ClutchWidget variant="home" />
            </div>
          </div>

          <div className="hidden sm:block sm:w-[40%]">
            <img src="/images/bannerImg.png" alt="banner" className="w-full" />
          </div>
        </div>
      </Container>
    </section>
  );
};
