import clsx from 'clsx';
import React from 'react';

import { Heading, Typography } from '@/components';
import { Container } from '@/layouts/Container';

import { ClientForm } from './ClientForm';
import type { FormProps } from './types';

export const ContactForm = ({
  onSubmit,
  withBackground = false,
  RenderAlert,
}: FormProps & {
  withBackground?: boolean;
  RenderAlert: () => JSX.Element | null;
}) => {
  return (
    <div
      className={clsx(
        'bg-gradient-to-tr from-secondary-100 to-siteBg py-mobileContainer sm:py-container',
        withBackground &&
          "bg-[url('/assets/common/contact-form-bg.png')] bg-cover bg-no-repeat"
      )}
    >
      <Container>
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-[96px]">
          <div className="basis-1/2" data-aos="fade-right">
            <Heading
              level={2}
              weight="semibold"
              className="text-2xl sm:text-[36px] sm:!leading-[44px]"
            >
              We have successfully completed 350+ projects across 15 industries.
            </Heading>
            <Typography
              variant="mediumDark"
              size="xl"
              className="mt-4 sm:mt-10"
            >
              Hire us for your next project. Request a free quote. We will send
              a precise calculation soon.
            </Typography>
          </div>
          <div className="basis-1/2" data-aos="fade-left">
            <div className="px-10">
              {RenderAlert()}
              <ClientForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
