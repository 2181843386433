const navItems = [
  {
    name: 'Success Stories',
    href: '/success-stories',
  },
  {
    name: 'Services',
    href: 'services',
    submenu: true,
    subMenuTitle: 'Our Services',
    subMenuSubTitle:
      '"We deliver tailored software solutions to boost business efficiency and productivity. Our team of experts combines cutting-edge technology with customized solutions. Upgrade your work with us."',
    subMenus: [
      {
        name: 'Mobile App Development',
        href: '',
      },
      {
        name: 'Enterprise Software Solutions',
        href: '',
      },
      {
        name: 'Web Application Development',
        href: '',
      },
      {
        name: 'UI/UX Design & Research',
        href: '',
      },
      {
        name: 'Development Team Augmentation',
        href: '',
      },
      {
        name: 'Startup Consultancy',
        href: '',
      },
      {
        name: 'Chatbot Development​',
        href: '',
      },
    ],
  },
  {
    name: 'Blog',
    href: 'blogs',
  },
  {
    name: 'Resources',
    href: '',
    submenu: true,
    subMenuTitle: 'Resources',
    subMenuSubTitle:
      'Explore data-rich insights from our content experts and tech geeks, bringing interesting updates from across the globe.',
    subMenus: [
      {
        name: 'News & Updates',
        href: '/news-updates',
      },
      {
        name: 'Developer Resources',
        href: '/developer-resources',
      },
    ],
  },
  {
    name: 'Career',
    href: '/career',
  },
  {
    name: 'About',
    href: '/about',
  },
];

const clientsData = [
  {
    id: 0,
    img: '/images/clients-brand/brand1.png',
  },
];

const services = [
  {
    id: 0,
    thumb: '/images/services/mobile.gif',
    title: 'Mobile App Development',
    subtitle:
      'We specialise in building native android and iOS apps that are bound to blow minds with aesthetics and performance.',
  },
  {
    id: 1,
    thumb: '/images/services/web.gif',
    title: 'Web Application Development',
    subtitle:
      'We build high performance websites and Cloud Services for the modern web that are ready to serve your business needs at any scale.',
  },
  {
    id: 2,
    thumb: '/images/services/chatbot.gif',
    title: 'Chatbot Development​',
    subtitle:
      'We are the first in Bangladesh to introduce chatbot technology to the banking sector. If your business cares about customers, Come to us.',
  },
  {
    id: 3,
    thumb: '/images/services/team.gif',
    title: 'Development Team Augmentation​',
    subtitle:
      'We staff and manage remote software development teams flexibly, cost-efficiently, and within tight deadlines.',
  },
  {
    id: 4,
    thumb: '/images/services/enterprise.gif',
    title: 'Enterprise Software Solution​',
    subtitle:
      'We develop intelligent and scalable systems that help enterprises of all sizes and shapes manage their business processes and data more effectively.',
  },
  {
    id: 5,
    thumb: '/images/services/ux.gif',
    title: 'UI/UX Design & Research​',
    subtitle:
      'We design human-centered, HCI research-based and great looking user interfaces following the best UX/UI practices.',
  },
  {
    id: 6,
    thumb: '/images/services/startup.gif',
    title: 'Startup Consultancy​',
    subtitle:
      'We show the path to the startup founders in which they should walk.  If you want to work in the startup industry, we will help you get started.',
  },
];

const achievements = [
  {
    id: 1,
    name: 'clutch',
    image: '/images/certificates/1.svg',
  },
  {
    id: 2,
    name: 'clutch',
    image: '/images/certificates/2.svg',
  },
  {
    id: 3,
    name: 'clutch',
    image: '/images/certificates/3.svg',
  },
  // {
  //   id: 4,
  //   name: 'clutch',
  //   image: '/images/certificates/4.svg',
  // },
  {
    id: 5,
    name: 'Best app development company',
    image: '/images/certificates/5.svg',
    link: 'https://www.designrush.com/',
  },
  {
    id: 6,
    name: 'Best web development company',
    image: '/images/certificates/6.svg',
    link: 'https://www.designrush.com/',
  },
  {
    id: 7,
    name: 'Top Mobile App Development Companies in USA',
    image: '/images/certificates/7.svg',
    link: 'https://findbestfirms.com/services/top-mobile-app-development-companies/',
  },
  {
    id: 8,
    name: 'Top Software Development Companies in USA',
    image: '/images/certificates/8.svg',
    link: 'https://findbestfirms.com/services/top-custom-software-development-companies/',
  },
  {
    id: 9,
    name: 'Top Software Development Companies in USA',
    image: '/images/certificates/9.svg',
    link: 'https://findbestfirms.com/services/top-web-development-companies/',
  },
];

const technology = [
  {
    id: 0,
    thumb: '/images/services/mobile.gif',
    title: 'Front-end',
    subtitle: 'ReactJS, AngularJS',
  },
  {
    id: 1,
    thumb: '/images/services/web.gif',
    title: 'Back-end',
    subtitle: 'NodeJS, Python, GoLang, Firebase, Java ',
  },
  {
    id: 2,
    thumb: '/images/services/chatbot.gif',
    title: 'Mobile Development​',
    subtitle: 'React Native, Flutter',
  },
  {
    id: 3,
    thumb: '/images/services/team.gif',
    title: 'AI/AL​',
    subtitle: 'NLP, Computer cesion, Deep learning, Tensorflow, Kears, PyTorch',
  },
  {
    id: 4,
    thumb: '/images/services/enterprise.gif',
    title: 'Blockchain​',
    subtitle:
      'NEM Symbol, Solidity, Corda, Hyperledger, Binance Smartchain, Ethereum',
  },
  {
    id: 5,
    thumb: '/images/services/ux.gif',
    title: 'Cloud​',
    subtitle:
      'Amazon Web Services, Google Cloud Platfrom, Microsoft Azure, Digital Ocean, Hetzner',
  },
  {
    id: 6,
    thumb: '/images/services/startup.gif',
    title: 'CI/CD​',
    subtitle: 'Gitlab CI, Jenkins CI, Github Action, CircleCL',
  },
];

const successStories = [
  {
    id: 0,
    name: 'Dhaka Bank',
    title: 'Dhaka Bank Chatbot Banking',
    subtitle:
      'Traditional Banking System, Online Banking, Mobile Banking and now comes the Interactive Banking System through WhatsApp in Bangladesh for the first time ever!',
    thumbImg: '/images/successStories/dhakaBank.svg',
    bgImg: '/images/successStories/dhakaBankWithBg.svg',
  },
  {
    id: 1,
    name: 'NYPD',
    title: 'App for NYPD Columbia: Bringing Together the Community',
    subtitle:
      "NYPD Columbia app is an interactive platform exclusively built for NYPD Columbia Association's members.",
    thumbImg: '/images/successStories/nypd.svg',
    bgImg: '/images/successStories/nypdWithBg.svg',
  },
  {
    id: 2,
    name: 'Amaan',
    title:
      'Custom Well-optimised HRMS, Payroll & Provident Fund Management Solution for AMANN Bangladesh Ltd.',
    subtitle:
      'Starting from new acquisition to compensation management, succession planning, etc. – our full-fledged HRM system software offers a single view of the whole company at a time.',
    thumbImg: '/images/successStories/aman.svg',
    bgImg: '/images/successStories/amanWithBg.svg',
  },
];

const testimonials = [
  {
    id: 0,
    company: 'Dhaka Bank',
    img: '/images/successStories/dhakaBank.svg',
    text: '"From the planning of the initiative to the deployment of the service, DISL has been the sole technology partner of the bank. The DISL team developed all the conversation flows, rules, templates, and admin panel. We have enjoyed working with DISL as a team and their attention to detail is commendable."',
    authorityUser: 'Tasin Taher',
    userImg: '/images/testimonialUser.svg',
    designation: 'Former Head, Retail Products and Digital Banking',
  },
  {
    id: 1,
    company: 'Dhaka Bank',
    img: '/images/successStories/dhakaBank.svg',
    text: '"From the planning of the initiative to the deployment of the service, DISL has been the sole technology partner of the bank. The DISL team developed all the conversation flows, rules, templates, and admin panel. We have enjoyed working with DISL as a team and their attention to detail is commendable."',
    authorityUser: 'Tasin Taher',
    userImg: '/images/testimonialUser.svg',
    designation: 'Former Head, Retail Products and Digital Banking',
  },
];

const counterValues = [
  {
    text: 'Founded',
    value: '2013',
  },
  {
    text: 'Projects',
    value: '350',
  },
  {
    text: 'Countries Served',
    value: '30',
  },
  {
    text: 'Industries Served',
    value: '15',
  },
];

const blogs = [
  {
    id: 0,
    tags: ['Software Develpment', 'Developer'],
    img: '/images/successStories/dhakaBankWithBg.svg',
    title: 'Bangladesh - Best Offshore Software Development Hub',
    date: '16 Jan 2022',
    duration: '5 min read',
    content:
      "Let's get you acquainted with one of the best Offshore Software Development Hubs in south Asia that was an underdog for years- Bangladesh.",
    author: {
      name: 'Dianne Russell',
      designation: 'Software Developer',
      img: '/images/testimonialUser.svg',
    },
  },

  {
    id: 1,
    tags: ['Software Develpment', 'Developer'],
    img: '/images/successStories/dhakaBankWithBg.svg',
    title: 'Bangladesh - Best Offshore Software Development Hub',
    date: '16 Jan 2022',
    duration: '5 min read',
    content:
      "Let's get you acquainted with one of the best Offshore Software Development Hubs in south Asia that was an underdog for years- Bangladesh.",
    author: {
      name: 'Dianne Russell',
      designation: 'Software Developer',
      img: '/images/testimonialUser.svg',
    },
  },
  {
    id: 2,
    tags: ['Project Management', 'Developer'],
    img: '/images/successStories/dhakaBankWithBg.svg',
    title: 'Bangladesh - Best Offshore Software Development Hub',
    date: '16 Jan 2022',
    duration: '5 min read',
    content:
      "Let's get you acquainted with one of the best Offshore Software Development Hubs in south Asia that was an underdog for years- Bangladesh.",
    author: {
      name: 'Dianne Russell',
      designation: 'Software Developer',
      img: '/images/testimonialUser.svg',
    },
  },
];

const featuredBlog = {
  id: 0,
  tags: ['Software Develpment', 'Developer'],
  img: '/images/successStories/dhakaBankWithBg.svg',
  title: 'Bangladesh - Best Offshore Software Development Hub',
  date: '16 Jan 2022',
  duration: '5 min read',
  content:
    "Let's get you acquainted with one of the best Offshore Software Development Hubs in south Asia that was an underdog for years- Bangladesh.",
  author: {
    name: 'Dianne Russell',
    designation: 'Software Developer',
    img: '/images/testimonialUser.svg',
  },
};
const footerAchievements = [
  { id: 0, img: '/images/footer_achievements/1.svg' },
  { id: 1, img: '/images/footer_achievements/2.svg' },
  { id: 2, img: '/images/footer_achievements/3.svg' },
  { id: 3, img: '/images/footer_achievements/4.svg' },
];

const footerSocialLinks = [
  {
    id: 0,
    name: 'fb',
    img: '/images/footer_social/fb.svg',
    href: 'https://www.facebook.com/dhrubokinfotech',
  },
  {
    id: 1,
    name: 'linkedin',
    img: '/images/footer_social/linkedin.svg',
    href: 'https://www.linkedin.com/company/dhrubokinfotech/?originalSubdomain=bd',
  },
  {
    id: 2,
    name: 'twitter',
    img: '/images/footer_social/twitter.svg',
    href: 'https://twitter.com/dhrubokinfotech',
  },
  {
    id: 3,
    name: 'insta',
    img: '/images/footer_social/insta.svg',
    href: 'https://www.instagram.com/dhrubok.infotech/',
  },
  {
    id: 4,
    name: 'behance',
    img: '/images/footer_social/behance.svg',
    href: 'https://www.behance.net/dhrubokinfotech',
  },
  {
    id: 5,
    name: 'medium',
    img: '/images/footer_social/medium.svg',
    href: 'https://dhrubokinfotech.medium.com/',
  },
];

const footerNavItems = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'Success Stories',
    href: '/success-stories',
  },
  {
    name: 'Services',
    href: '/services',
  },
  {
    name: 'Blog',
    href: '/blogs',
  },
  {
    name: 'News & Updates',
    href: '/news-updates',
  },
  {
    name: 'Developer Resources',
    href: '/developer-resources',
  },
  {
    name: 'Career',
    href: '/career',
  },
  {
    name: 'About',
    href: '/about',
  },
];

export {
  achievements,
  blogs,
  clientsData,
  counterValues,
  featuredBlog,
  footerAchievements,
  footerNavItems,
  footerSocialLinks,
  navItems,
  services,
  successStories,
  technology,
  testimonials,
};
