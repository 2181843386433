import React from 'react';

import { Heading, Tabs } from '@/components';
import { Container } from '@/layouts/Container';
import type { StartUpType } from '@/types/startup';

// const Seed = () => (
//   <div className="flex flex-col justify-between gap-5 sm:flex-row sm:gap-0">
//     <Typography size="xl" variant="lightDark" className="sm:px-20">
//       Get a helping hand through the design and development of your product.
//       With our go-to-market solutions, you can build your product faster,
//       smarter, and more efficiently — setting you on the path to further
//       funding..
//     </Typography>
//     <ul className="w-full list-inside list-disc text-xl text-primary-700">
//       <li>Product UI/UX</li>
//       <li>Proof of Concept</li>
//       <li>Market-focused MVP</li>
//       <li>Maintenance & support</li>
//       <li>Partnership & network</li>
//     </ul>
//   </div>
// );

export const StartUps = ({ startup }: { startup: StartUpType }) => {
  return (
    <Container>
      <div className="py-mobileContainer sm:py-container" data-aos="fade-in">
        <Heading level={2} className="mb-6 text-center sm:mb-14">
          How Do We Help Startups?
        </Heading>
        <Tabs startup={startup} />
      </div>
    </Container>
  );
};
