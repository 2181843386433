import clsx from 'clsx';
import React, { useCallback } from 'react';
import Slider from 'react-slick';

import type { ClientType } from '@/types/clients';
import getImage from '@/utils/getImage';

const settings = {
  dots: false,
  infinite: true,
  autoplaySpeed: 1,
  speed: 3000,
  slidesToShow: 8,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
};

export const ClientImages = ({ clients }: { clients: ClientType[] }) => {
  const handleClickWebsite = useCallback(
    (link: string, redirect: boolean) => {
      if (redirect) {
        window.open(link);
      }
    },
    [clients]
  );

  return (
    <Slider {...settings}>
      {clients
        .filter((c) => c.attributes.active === true)
        .map((client) => (
          <div key={client.id} className="h-[100px] py-2">
            <img
              src={getImage(client.attributes.logo?.data?.attributes.url)}
              alt={client.attributes.alt_logo}
              style={{
                objectFit: 'contain',
              }}
              className={clsx(
                client.attributes.hyperlink && 'cursor-pointer',
                'h-full w-full'
              )}
              onClick={() =>
                handleClickWebsite(
                  client.attributes.website,
                  client.attributes.hyperlink
                )
              }
            />
          </div>
        ))}
    </Slider>
  );
};
