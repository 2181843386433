import React from 'react';

import { Heading } from '@/components';
import { counterValues } from '@/constants/data';
import { Container } from '@/layouts/Container';
import type { TestimonialType } from '@/types/testimonial';

import { Counter } from '../Counter';
import { TestimonialSlider } from './TestimonialSlider';
// Styles...

export const Testimonials = ({
  testimonials,
}: {
  testimonials: TestimonialType[];
}) => {
  return (
    <section className="py-mobileContainer sm:py-container" data-aos="fade-in">
      <Container>
        <Heading
          level={2}
          className="p-0 text-center tracking-tightest sm:px-[66.5px] sm:py-default"
        >
          Testimonials
        </Heading>
        <div className="mt-mobileContainer space-y-7 sm:mt-0">
          <TestimonialSlider testimonials={testimonials} />
        </div>

        <div className="my-20 sm:my-container">
          <Counter counters={counterValues} />
        </div>
      </Container>
    </section>
  );
};
