import clsx from 'clsx';
import React from 'react';

import { Heading, MainCard, ScreenResizer } from '@/components';
import { Container } from '@/layouts/Container';

import type { ServicesListProps } from './types';

export const ServicesList = ({
  withText = true,
  services,
  cardContainerClass = '',
  cardButtons = true,
}: ServicesListProps) => {
  return (
    <section className="bg-primary-900 py-mobileContainer sm:py-container">
      <Container>
        {withText && (
          <div data-aos="fade-down">
            <Heading level={2} color="white" className="text-center">
              Our Services
            </Heading>
          </div>
        )}

        <ScreenResizer displayOnly="desktop">
          <div
            className={clsx(
              withText && 'mt-16',
              cardContainerClass.length > 0 && cardContainerClass,
              !(cardContainerClass.length > 0) && 'grid grid-cols-3 gap-6'
            )}
            data-aos="fade-in"
          >
            {services.map((item, idx) => {
              return (
                <MainCard
                  key={idx}
                  data={item}
                  i={idx}
                  withButton={cardButtons}
                />
              );
            })}
          </div>
        </ScreenResizer>

        <ScreenResizer displayOnly="mobile">
          <div
            className={clsx(
              withText && 'mt-6',
              cardContainerClass.length > 0 && cardContainerClass,
              !(cardContainerClass.length > 0) && 'grid grid-cols-1 gap-4'
            )}
          >
            {services.map((item, idx) => {
              return (
                <MainCard
                  key={idx}
                  data={item}
                  i={idx}
                  withButton={cardButtons}
                />
              );
            })}
          </div>
        </ScreenResizer>
      </Container>
    </section>
  );
};
