import React from 'react';

import { Heading, StoryCard } from '@/components';
import { Container } from '@/layouts/Container';
import type { SuccessStoriesType } from '@/types/successStories';

export const SuccessStory = ({
  successStories,
  title,
}: {
  successStories: SuccessStoriesType[];
  title: string;
}) => {
  return (
    <section className="py-mobileContainer sm:py-container">
      <Container>
        <div data-aos="fade-down">
          <Heading
            level={2}
            className="px-[66.5px] pb-6 text-center sm:pb-default"
          >
            {title}
          </Heading>
        </div>
        <div
          className={`grid grid-cols-1 gap-x-[149px] gap-y-4 sm:gap-y-[116px]`}
        >
          {successStories.map((item, i) => (
            <StoryCard key={i} idx={i} story={item} forHome />
          ))}
        </div>
      </Container>
    </section>
  );
};
