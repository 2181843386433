import Link from 'next/link';
import React from 'react';

import { Heading } from '@/components';
import { Container } from '@/layouts/Container';

import type { AchievementProps } from './types';

export const Achievements = ({ title, achievements }: AchievementProps) => {
  return (
    <section className="py-mobileContainer sm:py-container" data-aos="fade-in">
      <Container>
        <div className="flex justify-center">
          <Heading
            level={2}
            className="py-6 text-center sm:w-[938px] sm:px-[66.5px]"
          >
            {title}
          </Heading>
        </div>
        <div className={`mt-16 grid grid-cols-4 items-center gap-2 sm:gap-8`}>
          {achievements.map((achievment, i) => (
            <Link
              key={i}
              target="_blank"
              href={achievment.link || '#'}
              rel="nofollow"
            >
              <img
                src={achievment.image}
                alt={achievment.name}
                className="w-full object-cover object-center"
              />
            </Link>
          ))}
        </div>
      </Container>
    </section>
  );
};
